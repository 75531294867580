// src/pages/Artist100.js
import React, { useEffect, useState, useRef } from 'react';
const Artist100 = () => {
  const [data, setData] = useState(null);
  const [visibleArtists, setVisibleArtists] = useState(20);
  const [allArtists, setAllArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null); 
  const loaderRef = useRef(null);
  useEffect(() => {
    fetchDataAndRender();
	updateDocumentTitle();
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.1,
    });
    const currentLoaderRef = loaderRef.current;
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }
    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  }, [loaderRef]);
  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('json/billboard-artist-100.json');
      const jsonData = await response.json();
      setAllArtists(jsonData.data);
      setData(jsonData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  const openSearchPlatform = (platform, artist) => {
    if (!selectedArtist) return; 
    const artistQuery = encodeURIComponent(selectedArtist.name);
    const searchUrl =
      platform === 'youtubeMusic'
        ? `https://music.youtube.com/search?q=${artistQuery}`
        : platform === 'youtube'
        ? `https://youtube.com/results?search_query=${artistQuery}`
        : `https://open.spotify.com/search/${artistQuery}`;
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isiOS) {
      window.location.assign(searchUrl);
    } else {
      window.open(searchUrl, '_blank');
    }
  };
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisibleArtists((prevVisible) => prevVisible + 20);
    }
  };
  const handleImageClick = (artist) => {
    setSelectedArtist(artist);
  };
  const handleButtonClick = (platform) => {
    openSearchPlatform(platform, selectedArtist);
    setSelectedArtist(null); 
  };
  const renderChart = () => {
    if (!data) return null;
    return allArtists.slice(0, visibleArtists).map((artist, index) => (
      <div key={index} className="artist">
        <div>
          <img
            src={artist.image}
            alt={artist.name}
            loading="lazy"
            onClick={() => handleImageClick(artist)}
          />
          <p>
            {index + 1}. {artist.name}
          </p>
        </div>
        {selectedArtist && selectedArtist === artist && (
          <div className="search-options">
            <button onClick={() => handleButtonClick('spotify')}>
              <img src="spotify.png" alt="Spotify" />
            </button>
            <button onClick={() => handleButtonClick('youtubeMusic')}>
              <img src="ytm.png" alt="YouTube Music" />
            </button>
            <button onClick={() => handleButtonClick('youtube')}>
              <img src="youtube.png" alt="YouTube" />
            </button>
          </div>
        )}
      </div>
    ));
  };
      const updateDocumentTitle = () => {
    document.title = "Billboard Artist 100 - MusicBox";
  };
  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '75px' }}>
        {renderChart()}
      </div>
      <div ref={loaderRef}></div>
    </div>
  );
};
export default Artist100;