// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        src="favicon.png"
        alt="404"
        className="bw-img"
        style={{ width: '70px', height: '70px', marginTop: '100px' }}
      />
      <p style={{ textAlign: 'center', color: 'grey', fontSize: '40px' }}>404 Not Found</p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Link to="/" style={{ backgroundColor: 'grey', backgroundSize: 'cover', padding: '10px 20px', textDecoration: 'none', color: 'white' }}>
          Back to MusicBox
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
