import React, { useEffect, useState } from 'react';

const placeholderImage = 'record.svg';

const HomePage = () => {
  const [data, setData] = useState({
    billboard200Image: placeholderImage,
    artist100Image: placeholderImage,
    hot100Image: placeholderImage,
    global200Image: placeholderImage,
    itunesCNImage: placeholderImage,
    itunesTopImage: placeholderImage,
    neteaseArtistImage: placeholderImage,
    neteaseHotImage: placeholderImage,
    qqHitsImage: placeholderImage,
    appleClassicalImage: placeholderImage,
  });
  useEffect(() => {
    fetchData();
    document.title = "MusicBox - Source for the latest music charts and hits. Discover trending songs";
  }, []);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://music.whatshub.top/homepage');
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  return (
    <div className="container1">
      <a href="/hot100" className="box">
        <img src={data.hot100Image} alt="Billboard Hot 100" />
        <h2>Billboard Hot 100</h2>
      </a>
      <a href="/global200" className="box">
        <img src={data.global200Image} alt="Billboard Global 200" />
        <h2>Billboard Global 200</h2>
      </a>
      <a href="/200" className="box">
        <img src={data.billboard200Image} alt="Billboard 200" />
        <h2>Billboard 200</h2>
      </a>
      <a href="/artist100" className="box">
        <img src={data.artist100Image} alt="Billboard Artist 100" />
        <h2>Billboard Artist 100</h2>
      </a>
      <a href="/itopcn" className="box">
        <img src={data.itunesCNImage} alt="iTunes CN" />
        <h2>iTunes CN</h2>
      </a>
      <a href="/itops" className="box">
        <img src={data.itunesTopImage} alt="iTunes Top" />
        <h2>iTunes Top</h2>
      </a>
      <a href="/netartist" className="box">
        <img src={data.neteaseArtistImage} alt="Netease Artist" />
        <h2>Netease Artist</h2>
      </a>
      <a href="/nethot" className="box">
        <img src={data.neteaseHotImage} alt="Netease Hot" />
        <h2>Netease Hot</h2>
      </a>
      <a href="/qqhits" className="box">
        <img src={data.qqHitsImage} alt="QQ Hits" />
        <h2>QQ Hits</h2>
      </a>
    </div>
  );
};

export default HomePage;
