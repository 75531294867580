import React, { useState, useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const Hot100 = () => {
  const [visibleSongs, setVisibleSongs] = useState(20);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [lyrics, setLyrics] = useState([]);
  const [currentLyricIndex, setCurrentLyricIndex] = useState(0);
  const lyricsContainerRef = useRef(null);

  useEffect(() => {
    fetchDataAndRender();
    updateDocumentTitle();
    const savedVolume = localStorage.getItem('volume');
    if (savedVolume && isFinite(parseFloat(savedVolume))) {
      setVolume(parseFloat(savedVolume));
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.1,
    });
    const loaderRef = document.getElementById('loader');
    if (loaderRef) {
      observer.observe(loaderRef);
    }
    return () => {
      if (loaderRef) {
        observer.unobserve(loaderRef);
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('volume', volume.toString());
  }, [volume]);

  useEffect(() => {
    if (isPlaying && lyrics.length > 0) {
      const timer = setInterval(() => {
        const audio = document.getElementById('audio');
        if (audio) {
          const currentTime = audio.currentTime;
          const nextLyricIndex = lyrics.findIndex(
            (lyric, index) =>
              index < lyrics.length - 1 &&
              convertTimeToSeconds(lyric.time) <= currentTime &&
              currentTime < convertTimeToSeconds(lyrics[index + 1].time)
          );
          setCurrentLyricIndex(nextLyricIndex === -1 ? 0 : nextLyricIndex);
          scrollToCurrentLyric();
        }
      }, 500);
      return () => clearInterval(timer);
    }
	  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, lyrics]);
  const scrollToCurrentLyric = () => {
    if (lyricsContainerRef.current) {
      const currentLyricElement = lyricsContainerRef.current.childNodes[currentLyricIndex];
      if (currentLyricElement) {
        currentLyricElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('json/billboard-hot-100.json');
      const jsonData = await response.json();
      setAllSongs(jsonData.data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleImageClick = (index) => {
    setSelectedSongIndex(index);
    setIsPlaying(true);
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setVisibleSongs((prevVisible) => prevVisible + 20);
    }
  };

  const updateDocumentTitle = () => {
    document.title = 'Billboard Hot 100 - MusicBox';
  };

  const handleSongEnd = () => {
    setSelectedSongIndex((prevIndex) => (prevIndex + 1) % allSongs.length);
  };

  const handleSongError = () => {
    setSelectedSongIndex((prevIndex) => (prevIndex + 1) % allSongs.length);
  };

  const selectedSong = allSongs[selectedSongIndex];

  const convertTimeToSeconds = (time) => {
    const [minutes, seconds] = time.split(':').map(parseFloat);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    const fetchLyrics = async () => {
      try {
        const response = await fetch(
          `https://pub-9168946f9c9c45a4b101a3646feb8424.r2.dev/lyrics/${encodeURIComponent(
            selectedSong.name.replace(/[<>:"\\|?*]/g, '').toLowerCase()
          )} - ${encodeURIComponent(selectedSong.artist.replace(/[<>:"\\|?*]/g, '').toLowerCase())}.lrc`
        );
        const text = await response.text();
        const lines = text.split('\n').filter((line) => line.trim() !== '');
        const parsedLyrics = lines.map((line) => {
          const [time, content] = line.split(']');
          return {
            time: time.substring(1),
            content,
          };
        });
        setLyrics(parsedLyrics);
      } catch (error) {
        console.error('Failed to fetch lyrics:', error);
        setLyrics([]);
      }
    };

    if (selectedSong) {
      fetchLyrics();
    }
  }, [selectedSong]);

  const getDisplayedLyrics = () => {
    if (lyrics.length === 0 || currentLyricIndex === -1) {
      return [];
    }

    const startIndex = Math.max(0, currentLyricIndex - 1);
    const endIndex = Math.min(lyrics.length - 1, currentLyricIndex + 1);
    return lyrics.slice(startIndex, endIndex + 1);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '75px', marginBottom: '150px' }}>
        {allSongs.slice(0, visibleSongs).map((song, index) => (
          <div key={index} className="song">
            <div>
              <img
                src={song.image}
                alt={song.name}
                loading="lazy"
                onClick={() => handleImageClick(index)}
                className={selectedSongIndex === index && isPlaying ? 'selected rotate' : 'song'}
              />
              <p>
                {song.rank}. {song.name}
                <br />- {song.artist}
              </p>
            </div>
          </div>
        ))}
      </div>
<div id="loader"></div>
{selectedSong && (
  <div className="loader" >
    <div ref={lyricsContainerRef} className="lyrics-container">
      {getDisplayedLyrics().map((lyric, index) => (
        <p key={index} className={index === 1 ? 'bold-black' : 's-black'}>{lyric.content}</p>
      ))}
    </div>

    <ReactAudioPlayer
      id="audio"
      src={`https://pub-9168946f9c9c45a4b101a3646feb8424.r2.dev/audio/${encodeURIComponent(selectedSong.name.replace(/[<>:"\\|?*]/g, '').toLowerCase())} - ${encodeURIComponent(selectedSong.artist.replace(/[<>:"\\|?*]/g, '').toLowerCase())}.mp3`}
      autoPlay={isPlaying}
      controls
      volume={isFinite(volume) ? volume : 1}
      onEnded={handleSongEnd}
      onError={handleSongError}
      onVolumeChanged={(e) => setVolume(parseFloat(e.target.volume))}
    />
	    <p style={{ color:'black',margin:'10px', fontWeight: 'bold',fontSize: '14px', overflowWrap: 'break-word', textAlign: 'center' }}>{selectedSong.name} - {selectedSong.artist}</p>
  </div>
)}

      )}
    </div>
  );
};

export default Hot100;
