// src/pages/About.js
import React from 'react';

const About = () => {
  return (
    <div>
      <div style={{ marginTop: '100px', color: '#ccc' }}>
        <h2>About MusicBox</h2>
        <p>Welcome to MusicBox, where you can explore the latest updates on the Billboard charts. MusicBox provides real-time information on Billboard, iTunes, QQMusic, and NetEaseMusic charts.</p>
        <h3>Key Features</h3>
        <ul>
          <li>Explore Billboard, iTunes, QQMusic, and NetEaseMusic rankings.</li>
          <li>Click on Spotify, YouTube, or YouTube Music buttons to seamlessly navigate to your preferred music apps or sites.</li>
        </ul>
        <h3>What's New</h3>
		<ul>
		<li>New Feature: Online Streaming - Now you can enjoy online streaming directly through MusicBox! However, please note that the streaming feature is not synchronized with the chart data. Additionally, due to storage limitations, the audio quality may not be optimal as it is stored in a free R2 storage bucket. Thank you for your understanding.</li></ul>
        <h3>Contact</h3>
        <p>If you have any feedback, suggestions, or inquiries, feel free to reach out to me at <a href="mailto:mail@whatshub.top" style={{ color: '#b8b4b4' }}>mail@whatshub.top</a>.</p>
		<br/>
        <p>Thank you for using MusicBox! Enjoy exploring.</p>
        <a href="https://github.com/deezertidal" target="_blank" rel="noopener noreferrer">
          <img src="https://img.shields.io/badge/GitHub-Visit-181717?style=for-the-badge&logo=github" alt="GitHub Logo" />
        </a>
      </div>
    </div>
  );
};
export default About;
